import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";

import "../.././css/shareButton.css";
const ShareButtons = ({ url, title }) => {
  const currentURL = window.location.href;

  return (
    <div className="share-buttons">
      <FacebookShareButton url={currentURL} quote={title}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={currentURL} title={title}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={currentURL} title={title}>
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>

      <WhatsappShareButton url={currentURL} title={title}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>

      <TelegramShareButton url={currentURL} title={title}>
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
    </div>
  );
};

export default ShareButtons;
